import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import 'swiper/css';
import 'swiper/css/pagination';
import ReCAPTCHA from 'react-google-recaptcha';



export default function Footer() {


    const [isVerified, setIsVerified] = useState(false);

    const handleRecaptchaChange = (token) => {
      console.log('reCAPTCHA token:', token);
      setIsVerified(true); // Set verified state to true
    };

 
    const form = useRef();

    function changeValue(){
        const inputs = document.querySelectorAll('.foot-form');
      inputs.forEach(function(e){
          e.value = ""
      })
    }
    
    const sendEmail = (e) => {
      e.preventDefault();
  

      emailjs
        .sendForm('service_astjhkk', 'template_ezwf7fq', form.current, {
          publicKey: 'G5epzNHERbqK1eCUt',
        })
        .then(
          () => {
            alert('Thankyou! Your Message has been sent successfully we will respond you soon!');
            changeValue();
          },
          (error) => {
            alert('Sorry Because of Some Issue your Message could not be sent', error.text);
          },
        );
    };   

    
    

  return (
    <>

  

        <section className="padding bg-white footer" >
            <div className="container">
                
                    <h2 className='fw-bold text-center mb-0 text-blue'>
                      Let MPA help you deliver
                    </h2>
                    <h2 className='fw-bold text-center text-blue'>
                      exceptional customer experiences.
                    </h2>
        
            <div className='my-auto text-center mt-3'>
              <Link to="/contact-us" className="btn btn-1 fw-bold p-2 px-4 ">Contact Us</Link>
            </div>
                
                
            </div>
        </section>
       
                                
        

      <footer className="bg-blue ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 text-md-start text-center" style={{'borderRight': '0.5px solid rgba(255,255,255,0.5)',
    'borderCollapse': 'collapse'}}>
                        <Link to="/">
                            <img src="static/images/Logos.svg"  alt="" loading="lazy" className='img-fluid footer-logo' ></img>
                        </Link>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-4 px-3">
                            <div>
                              <h4 className="mb-3 text-white">
                                  Company
                              </h4>
                              <ul className='ul-v'>
                                  <li><Link to="/">Home</Link></li>
                                  <li><Link to="/about">About</Link></li>
                                  <li><Link to="/services">Services</Link></li>
                                  <li><Link to="/portfolio">Portfolio</Link></li>
                                  <li><Link to="/packages">Packages</Link></li>
                                  <li><Link to="/career">Career</Link></li>
                                  <li><Link to="https://edu.mediaproductionart.com/" target='_blank'>Education</Link></li>
                                  <li><Link to="/contact-us">Contact</Link></li>
                              </ul>
                            </div>

                           
                          </div>

                          <div className="col-lg-4">
                                <div>
                                  <h4 className=" mb-3 text-white">
                                      Our Portfolio
                                  </h4>
                                  <ul className='ul-v'>
                                      <li><Link to="/web-development">Web Development </Link></li>
                                      <li><Link to="/graphic-design">Graphic Design </Link></li>
                                      <li><Link to="/packaging-design">Packaging Design </Link></li>
                                      <li><Link to="/logo-design">Logo Design </Link></li>
                                      
                                  </ul>
                                </div>

                                <div className="mt-5">
                                    <div className='my-auto d-flex'>
                                      <h4 className=" text-white">
                                          United States 
                                      </h4>
                                      <span>
                                          <img src="static/images/flags2.webp"  alt="" loading="lazy" className='mx-1 img-fluid' width="30px"></img>
                                      </span>
                                    </div>

                                    <div className="text-start">

                                
                                        <div className="my-auto">
                                              <a href='https://api.whatsapp.com/send/?phone=18135305507&text&type=phone_number&app_absent=0' target='_blank' rel="noreferrer" className='text-white my-auto'>+1 (813) 530-5507</a>
                                            
                                        </div>

                                </div>

                                  

                                
                                
                                <div className="my-2 pt-0">   
                                    <p className='my-auto'>
                                      <a href="mailto:design@tajanna.com" className='text-white'>design@tajanna.com</a>
                                    </p>
                                  </div>
                                </div>

                                <div className="mt-2">
                                   
                                   <p className='text-white'>19046 Bruce B Downs Blvd Ste B6 #844 Tampa, FL 33647</p>
                                  
                                  </div>
                                
                                <div>
                                  <img src="static/images/tajanna.png"  alt="" loading="lazy" className="img-fluid" width="100px"></img>
                                </div>
                                  
                                


                            </div>

                            <div className="col-lg-4">
                            <div className="">
                                  <div className='d-flex'>
                                  <h4 className=" mb-2 text-white">
                                      Pakistan
                                  </h4>
                                  <span>

                                    <img src="static/images/flag1.webp"  alt="" loading="lazy" className='mx-1 img-fluid' width="30px"></img>
                                  </span>
                                  </div>

                                  
                                
                                
                            <div className='mt-2 text-start'>

                                <div className="my-auto">
                                  <div className="text-start my-0 py-0">

                                  
                                    <div className="my-auto ">
                                          <a href='https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0' target='_blank' rel="noreferrer" className='text-white '>(+92) 313 387-6798</a>
                                    </div>
                                </div>
                              </div>

                              </div>


                            </div>

                            <div className="mb-0 pb-0 mt-2 ">
                                  
                                
                                  <p className=''>
                                  <a href="mailto:media.production.art@gmail.com" target='_blank' rel="noreferrer" className='text-white'>media.production.art@gmail.com</a>
                                  </p>
                              </div>

                              
                              <div className=''>
                                    
                                    <p className='text-white'> First Floor، Raeesa Apartment، Unit 7 Latifabad, Hyderabad</p>
                                  
                                  </div>
                        
                            <div className="mt-5">
                              <h4 className="text-white">Follow Us</h4>
                              <div className="footer-icons">
                                  <Link to="https://www.facebook.com/mediaproductionart" target="_blank" className='m-0'><i className="fa-brands fa-facebook-f"></i></Link>
                                  <Link to="https://www.instagram.com/mediaproductionart/" target="_blank"><i className="fa-brands fa-instagram"></i></Link>
                                  <Link to="https://www.youtube.com/@mediaproductionarteducation" target="_blank"><i className="fa-brands fa-youtube "></i></Link>
                                  <Link to="https://www.linkedin.com/in/mediaproductionart/" target="_blank" ><i className="fa-brands fa-linkedin "></i></Link>
                                  <Link to="https://www.tiktok.com/@mediaproductionart" target="_blank"><i className="fa-brands fa-tiktok"></i></Link>
                              </div>
                            </div>
                         
                            </div>


                        </div>
                    </div>
                       
                  

                   

               
                  

                </div>

            </div>
                  <div className="pt-3 text-white" style={{borderTop:'0.5px solid rgba(255,255,255,0.5)'}}>
                      <div className="container text-center">
                          <p>© 2011-2024 Media Production Art ALL RIGHTS RESERVED <span className=''>Privacy Policy | Terms of Use</span></p>
                      </div>
                  </div>
      </footer>
    </>
  )
}

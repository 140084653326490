import React from 'react'
import { Link } from 'react-router-dom';
import Testimonials from './Testimonials';




export default function Services(props) {
  return (
    <>
        <section className='padding service-banner'>
          <div className="container mt-5 pt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <h1>
                          We make design that lead and inspire.
                      </h1>
                      <p className='mx-auto text-secondary text-justify service-p'>
                      <span className="mx-5"></span> We take a similar approach to design commercial we do impactfully approache, over the flowchart of user friendly wireframe.
                      </p>
                      <a className="arrow-ball mx-auto" href="#service-target">
                          <i class="fa-solid fa-arrow-down"></i>
                      </a>
                    </div>
                    <div className="col-md-6 mt-md-0 mt-3">
                          <img src="static/images/about/1.jpg" alt="" className='img-fluid' style={{'height':'80vh', 'objectFit':'cover'}}></img>
                    </div>
                  </div>
          </div>
        </section>

        <section className="padding" id="service-target">
          <div className="container">
             
             


              <div className="row my-5">
              <div className="col-md-6">
                  
                  <div className="">
                      <img src="static/images/services/1.svg"  alt="" loading="lazy" width={'60px'} className='img-fluid text-end'></img>
                    <h1 className='lined2 mt-4 fs-1'>Excellent Graphic Designing Services</h1>

                  </div>

                </div>

                <div className="col-md-6 my-auto mt-md-auto mt-4">
               
                <p>Our Graphic Design services are tailored to elevate your brand with visually stunning and impactful designs. From logos to marketing materials, we create eye-catching visuals that communicate your message and resonate with your audience.</p>

                <ul>
                  <li>Logo Design: Craft a unique and memorable logo that embodies your brand's identity.</li>
                  <li>Marketing Materials: Design brochures, flyers, and other collateral that effectively promote your products or services.</li>
                  <li>Brand Identity: Develop a cohesive visual identity that enhances brand recognition across all platforms.</li>
                </ul>
                  
                </div>
              </div>

              <div className="row my-5 py-5 ">
               

                <div className="col-md-6 my-auto order-md-1 order-2 mt-md-auto mt-4">
                

                    <p>Our Digital Marketing solutions are designed to accelerate your business and reach new heights. From targeted Facebook Meta ads that capture attention to dynamic social media campaigns that engage your audience and platform-specific ads that drive results, we deliver tailored solutions that exceed expectations.
                    </p>

                    <ul>
                      <li>Facebook Meta Ads: Captivate your audience with targeted and visually stunning ads on the Facebook Meta platform.</li>
                      <li>Social Media Campaigns: Engage your audience with dynamic and impactful campaigns across various social media channels.</li>
                      <li>Social Media Platform Ads: Drive results with platform-specific ads tailored to your target audience and business goals.</li>
                      
                    </ul>
                    
                </div>

               
                <div className="col-md-6 order-md-2 order-1">
                  
                  <div className="">
                      <img src="static/images/services/4.svg"  alt="" loading="lazy" width={'60px'} className='img-fluid text-end'></img>
                    <h1 className='lined2 mt-4 fs-1'>Digital Marketing Services</h1>

                  </div>

                </div>

              </div>


              <div className="row my-5">
              <div className="col-md-6 ">
                  
                  <div className="">
                      <img src="static/images/services/3.svg"  alt="" loading="lazy" width={'60px'} className='img-fluid text-end'></img>
                    <h1 className='lined2 mt-4 fs-1'>Website UI/UX Designing Services</h1>

                  </div>

                </div>

                <div className="col-md-6 my-auto mt-md-auto mt-4">
                
                <p>Our Website Design services are meticulously tailored to elevate your brand and achieve your goals. From captivating layouts that capture attention to seamless user experiences that drive engagement, we deliver excellence in every aspect of website design. 
                    </p>

                    <ul>
                      <li>Captivating layouts that capture the attention.</li>
                      <li>Seamless user experiences that drive engagement.</li>
                      <li>Customized solutions tailored to your brand's goals.</li>
                      <li>Elevate your brand's online presence with our expert website design services.</li>
                    </ul>
                  
                </div>
              </div>

              <div className="row my-5 py-5 ">
               

                <div className="col-md-6 my-auto order-md-1 order-2 mt-md-auto mt-4">
                  <p>Our Website Development services are designed to revolutionize your online presence and drive tangible results. From basic websites that establish your digital footprint to informative websites that educate and engage your audience, and eCommerce stores that drive sales and revenue, we deliver excellence in every project.

                  </p>

                  <ul>
                    <li>Basic Website: Establish your digital footprint with a simple yet effective online presence.</li>
                    <li>Informative Website: Educate and engage your audience with informative content and seamless navigation.</li>
                    <li>eCommerce Store Website: Drive sales and revenue with a fully functional eCommerce platform tailored to your business needs.</li>

                  </ul>
                   
                   
                </div>

                <div className="col-md-6 order-md-2 order-1">
                  
                  <div className="">
                      <img src="static/images/services/2.svg"  alt="" loading="lazy" width={'60px'} className='img-fluid text-end'></img>
                      <h1 className='lined2 mt-4 fs-1'>Expert Website Development Services</h1>

                  </div>

                </div>

              </div>


              <div className="row my-5">
              <div className="col-md-6 ">
                  
                  <div className="">
                      <img src="static/images/services/5.svg"  alt="" loading="lazy" width={'60px'} className='img-fluid text-end'></img>
                    <h1 className='lined2 mt-4 fs-1'>Mobile Application Development</h1>

                  </div>

                </div>

                <div className="col-md-6 my-auto mt-md-auto mt-4">
                <p>Our App Development services are designed to enhance user experience and engagement across platforms. From intuitive mobile apps that meet your customers' needs to robust web applications that streamline operations, we deliver excellence in every project.</p>

                <ul>
                  <li>Mobile App: Create intuitive mobile applications that provide seamless user experiences and drive engagement.</li>
                  <li>Web Application: Develop robust web applications that enhance functionality and improve operational efficiency.</li>
                  <li>Cross-Platform Solutions: Ensure consistent performance across devices with our cross-platform app development services.</li>
                </ul>
                    
                   
                </div>
              </div>

              <div className="row my-5 py-5 ">
               

                <div className="col-md-6 my-auto order-md-1 order-2 mt-md-auto mt-4">
                 
                <p>Our Social Media Marketing services are designed to amplify your brand's online presence and foster community engagement. We create tailored strategies that connect you with your audience, driving awareness and loyalty across various platforms.</p>

                <ul>
                  <li>Content Creation: Develop engaging content that resonates with your audience and reflects your brand's voice.</li>
                  <li>Strategic Campaigns: Implement targeted campaigns that boost brand visibility and drive traffic to your channels.</li>
                  <li>Analytics and Reporting: Monitor performance with in-depth analytics to optimize strategies and achieve measurable results.</li>
                </ul>
                   
                </div>

                <div className="col-md-6 order-md-2 order-1">
                  
                  <div className="">
                      <img src="static/images/services/6.svg"  alt="" loading="lazy" width={'60px'} className='img-fluid text-end'></img>
                    <h1 className='lined2 mt-4 fs-1'>Social Media Marketing Services</h1>

                  </div>

                </div>

              </div>

              <div className="row my-5">
              <div className="col-md-6 ">
                  
                  <div className="">
                      <img src="static/images/services/7.svg"  alt="" loading="lazy" width={'60px'} className='img-fluid text-end'></img>
                    <h1 className='lined2 mt-4 fs-1'>Content Writting / Creation Services</h1>

                  </div>

                </div>

                <div className="col-md-6 my-auto mt-md-auto mt-4">
                <p>Our Content Writing services are crafted to enhance your brand's voice and engage your audience effectively. We produce high-quality, compelling content that informs, entertains, and drives conversions across various platforms.</p>

                  <ul>
                    <li>Website Copy: Create persuasive and informative copy that enhances your online presence and engages visitors.</li>
                    <li>Blog Writing: Develop insightful blog posts that position your brand as an authority and encourage audience interaction.</li>
                    <li>SEO Content: Optimize content for search engines to improve visibility and attract organic traffic to your site.</li>
                  </ul>
                                  
                   
                      
                </div>
              </div>



          </div>
        </section>

        <Testimonials/>

        
    </>
  )
}

import React from 'react'
// import $ from 'jquery'; // Import jQuery first


import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'; // Import Fancybox CSS
import '@fancyapps/fancybox'; // Import Fancybox library




export default function Portfolio(props) {

    // useEffect(() => {
    //     try {
    //         $('[data-fancybox="gallery"]').fancybox();

    //     } catch (error) {
    //         console.error('Error initializing Fancybox:', error);
    //     }
    // }, []);
  return (
    <>
     
           
            <div className="row gx-4">
                <div className=" col-md-6 mt-4">
                    <div className="folio-card" style={{background:`url(${props.image1})`,backgroundSize:'cover',backgroundPosition:'center'}}>
                        <a href={props.link1} data-fancybox="gallery" className=''>
                        <div className="card-overlay ">
                            <div className="row">
                                <div className="col-10">
                                    <h3 className="fw-bold text-white mt-2">{props.name1}</h3>
                                    <p className="text-white fs-6 mt-3">Project was about precision and information...</p>
                                </div>
                                <div className="col-2 my-auto">
                                    <div className='text-end'>
                                        <i className="fa-solid fa-arrow-up-long text-white arrow-icon"></i>
                                    </div>
                                </div>    
                            </div>       
                        </div>
                        </a>
                    </div>
                
                </div>
                
                <div className=" col-md-6 mt-4">
                    <div className="folio-card " style={{background:`url(${props.image2})`,backgroundSize:'cover',backgroundPosition:'center'}}>
                    <a href={props.link2} data-fancybox="gallery" className=''>
                        <div className="card-overlay ">
                            <div className="row">
                                <div className="col-10">
                                    <h3 className="fw-bold text-white mt-2">{props.name2}</h3>
                                    <p className="text-white fs-6 mt-3">Project was about precision and information...</p>
                                </div>
                                <div className="col-2 my-auto">
                                    <div className='text-end'>
                                        <i className="fa-solid fa-arrow-up-long text-white arrow-icon"></i>
                                    </div>
                                </div>    
                            </div>       
                        </div>
                        </a>
                    </div>
                   
                </div>


                

            </div>
        
    </>
  )
}

import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';




export default function Contact() {
    
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const navigate = useNavigate(); // Using useNavigate hook

  const redirect = () => {
      // Navigate to the desired URL
      navigate('/');
  };

  const handleRecaptchaChange = (token) => {
      console.log('reCAPTCHA token:', token);
      setIsVerified(true); // Set verified state to true
  };
  function changeValue(){
      const inputs = document.querySelectorAll('.career-input');
    inputs.forEach(function(e){
        e.value = ""
    });

    setTimeout(() => {
      redirect(); // Call the redirect function inside setTimeout
  }, 2000);
  }

  const form = useRef();

  function sendEmail(e) {
      e.preventDefault();

      if (!isVerified) {
          setErrorMessage('Please verify reCAPTCHA before sending the message.');
          return;
      }

      emailjs
      .sendForm('service_astjhkk', 'template_ezwf7fq', form.current, {
        publicKey: 'G5epzNHERbqK1eCUt',
      }).then(() => {
              setErrorMessage('Thank you! Your request has been submitted successfully. We will respond to you soon.');
              changeValue();
          })
          .catch((error) => {
              setErrorMessage(`Sorry, because of some issue your message could not be sent: ${error}`);
          });
  };

  return (  
    <>
      <section className='padding contact-banner'>
        <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4">
                <h1>
                Any questions? simply
                ask us this is you home!
                </h1>
              </div>
              <div className="col-lg-8 mt-lg-0 mt-3">
                <div className="row">
                <div className="col-lg-4">
                    <div className='mt-5 pt-5'>
                      <img src="static/images/contact/3.jpg" alt="" className='img-fluid'></img>
                    </div>
                    <div className='mt-3 p-3 py-4 bg-dark'>
                        <div className="text-end">
                          <img src="static/images/1.webp" alt="" className='img-fluid' width={'100px'} style={{'opacity':'0.3'}}></img>
                        </div>
                        <div>
                          <h6 className='fw-600 text-secondary'>
                            Direct Contact:
                          </h6>
                          <h5 className='text-white mt-3'>
                          +92 313 387-6798
                          </h5>
                        </div>  
                    </div>
                </div>
                
                <div className="col-lg-8">
                    <div>
                      <img src="static/images/contact/2.jpg" alt="" className='img-fluid'></img>
                    </div>
                    <div className='mt-4'>
                      <img src="static/images/contact/6.jpg" alt="" className='img-fluid'></img>
                    </div>
                </div>
                </div>
              </div>
            </div>
          
        </div>
      </section>

   
      <section className="padding">
        <div className="container-fluid">
            

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.9323331912747!2d68.36001921045082!3d25.373584224441284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x394c705f5c9f56ab%3A0x84542d1b7f63f413!2sMedia%20Production%20Art!5e0!3m2!1sen!2s!4v1709902724005!5m2!1sen!2s" width="100%" height="300" title='MPA Location' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>  

        <div className="container-fluid mt-5 pt-5">
            <div className="row">
              <div className="col-lg-8">
                <h1>
                  We’ve been waiting for you!
                </h1>
              </div>
              <div className="col-lg-4 mt-3">
                  <p className='fw-600'>Add the best talent on the market, an agile skilled management & seamless involvement</p>
              </div>
                <div className="col-lg-4 my-auto">
                   <h5 className="fw-bold mb-3">We want to hear from you. let’s us know how we can help!</h5>
                    <div className='mt-2'>
                                <div className="mt-3 d-flex">
                                  
                                    <div className='my-atuo'>
                                      <span className='fw-bold'>United States</span>
                                      <img src="static/images/flags2.webp" alt="" loading="lazy" className='mx-1 img-fluid' width="30px"></img>
                                    </div>
                                  </div>
                          <p className="pt-0 mt-2 fs-14 fw-600"><a href='https://api.whatsapp.com/send/?phone=18135305507&text&type=phone_number&app_absent=0' target='_blank' rel="noreferrer" className=''>+1 (813) 530-5507</a></p>

                          <p className="my-0 pb-1 fw-600"><a href="mailto:design@tajanna.com" target='_blank' rel="noreferrer" className='fs-14'>design@tajanna.com</a></p>

                          <div className='mt-2'>
                        
                            <p className="mt-1 fs-14 fw-600">
                              <p className='mb-0 pb-0'>19046 Bruce B Downs Blvd</p>
                              <p className='mb-0 pb-0'>Ste B6 #844 Tampa, FL 33647</p>
                              
                            </p>

                            </div>
                          
                    
                         
                          
                          
                    
                    <div className='mt-2'>
                           <div className="">
                            <span className='fw-bold'>Pakistan</span>
                            <img src="static/images/flag1.webp" alt=""  loading="lazy" className='mx-1 img-fluid' width="30px"></img>        
                          </div>
                          
                          <p className="mt-1 mb-0 py-1 "><a href='https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0' target='_blank' rel="noreferrer" className='fs-14 fw-600'>+92 313 387-6798</a></p>

                          <p className="mt-1 mb-0 pb-1 fs-14"><a href="mailto:media.production.art@gmail.com" target='_blank' rel="noreferrer" className='d-sm-block d-none fw-600'>media.production.art@gmail.com</a>
                          <a href="mailto:media.production.art@gmail.com" target='_blank' rel="noreferrer" className='d-sm-none d-block fs-14 fw-600'>media.production.art @gmail.com</a>
                          
                          </p>       
                          <p className="mt-2 fs-14 fw-600">
                              <p className='mb-0 pb-0 fw-600'>First Floor، Raeesa Apartment</p>
                              <p className='mb-0 pb-0 fw-600'>Unit 7 Latifabad Hyderabad Pakistan</p>
                              
                            </p>
                    </div>
                    
             
                    </div>
                </div>
                <div className="col-lg-8 mt-lg-auto mt-4 my-auto">
                    <div className=''>
                  
                        <form ref={form} action="" className='row' onSubmit={sendEmail}>
                            <div className='mt-3 col-lg-6'>
                                <input type="text" required className='input w-100' placeholder="Name" name="from_name" />
                            </div>
                            <div className='mt-3 col-lg-6 '>
                                <input type="tel"  className='input w-100' placeholder="Phone Number" name="from_phone"/>
                            </div>
                            <div className='mt-5 col-lg-6'>
                                <input type="email" required className='input w-100' placeholder="Email Address" name="from_email" />
                            </div>
                            <div className='mt-5 col-lg-6'>
                                <input type="text" required className='input w-100' placeholder="Subject" name="" />
                            </div>
                            
                            <div className='mt-5 col-12'>
                                <textarea type="text" required className='input w-100 ' rows="5" placeholder="Your Message" name="message" />
                            </div>
                            <div className="my-3">
                                <ReCAPTCHA
                                      sitekey="6LdJcMApAAAAAEU7BVWoqoVRUKHWoScN9r_wF8yc"
                                      onChange={handleRecaptchaChange}
                                      className='recaptcha'
                                      style={{width:'300px'}}
                                    />
                              </div>
                              <div className='mt-3'>
                                        {errorMessage && (
                                        <p className='fw-bold text-success'>
                                            {errorMessage}
                                            {setTimeout(() => setErrorMessage(''), 5000)}
                                        </p>
                                )}
                              </div>
                              <div>

                              <button  type="submit" class="btn-15 mt-0" disabled={!isVerified}><span>Send Message</span></button>
                              </div>
                         
                            
                        </form>
                    </div>
                    
                          
                        

                   
                </div>
            </div>
        </div>
        </div>
      </section>  

      

    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useState } from 'react';


export default function Servicecard() {

  const [activeSlide, setActiveSlide] = useState('custom-slide1');
  
  const handleTriggerClick = (slideId) => {
    setActiveSlide(slideId);
  }
  

  
 


  

  return (
    <>
     {/* section starts here..............  */}
        <section className='padding new-services'>
            <div className="row w-100 gx-0">
              <div className="col-lg-8 my-auto">
                  <div className="container px-lg-5 px-4">
                  <div className="row">
                      <div className="col-lg-6">
                        <h6 className='fw-bold'>Production and Technology</h6>
                        <h1 className='fw-bold mt-5'>Services</h1>
                        <p className="mt-5 fs-14" style={{'textAlign':'justify'}}>
                        Our services encompass all aspects of your digital presence, including graphic design that brings your brand to life and digital marketing strategies that drive engagement. We provide user-friendly web design and secure website development tailored to your needs. Our app development creates seamless mobile applications, while social media marketing builds your online community through targeted campaigns. Additionally, our content writing service delivers high-quality content to enhance your brand's message across platforms.
                        </p>
                      </div>
                      <div className="col-lg-6">
                         <div className="row">

                          <div className="col-6 mb-3 mx-auto">
                            <div className=''>
                              <h5 className='numbers fw-bold'>01</h5>
                              <div className="" id="slide-trig1" onClick={() => handleTriggerClick('custom-slide1')}>
                                <h6 className={`mt-2 mb-0 ${activeSlide === 'custom-slide1' ? 'active-color' : ''}`}>Graphic</h6>
                                <h6 className={`${activeSlide === 'custom-slide1' ? 'active-color' : ''}`}> Designing</h6>
                              </div>
                            </div>

                          </div>

                          <div className="col-6 mb-3">
                            <div className=''>
                              <h5 className='numbers fw-bold'>02</h5>
                              <div className="" id="slide-trig2"  onClick={() => handleTriggerClick('custom-slide2')}>
                                <h6 className={`mt-2 mb-0 ${activeSlide === 'custom-slide2' ? 'active-color' : ''}`}>Digital</h6>
                                <h6 className={`${activeSlide === 'custom-slide2' ? 'active-color' : ''}`}>Marketing</h6>
                              </div>
                            </div>

                          </div>

                          <div className="col-6 mb-3">
                            <div className='slide-trig3'>
                              <h5 className='numbers fw-bold'>03</h5>
                              
                              <div className="" id="slide-trig3"  onClick={() => handleTriggerClick('custom-slide3')}>
                                <h6 className={`mt-2 mb-0 ${activeSlide === 'custom-slide3' ? 'active-color' : ''}`}>Website</h6>
                                <h6 className={`${activeSlide === 'custom-slide3' ? 'active-color' : ''}`}>Designing</h6>
                              </div>
                            </div>

                          </div>

                          <div className="col-6 mb-3">
                            <div className='slide-trig4'>
                              <h5 className='numbers fw-bold'>04</h5>
                              <div className="" id="slide-trig4"  onClick={() => handleTriggerClick('custom-slide4')}>
                                <h6 className={`mt-2 mb-0 ${activeSlide === 'custom-slide4' ? 'active-color' : ''}`}>Website</h6>
                                <h6 className={`${activeSlide === 'custom-slide4' ? 'active-color' : ''}`}> Development</h6>
                              </div>
                            </div>

                          </div>

                          <div className="col-6 mb-3">
                            <div className=''>
                              <h5 className='numbers fw-bold'>05</h5>
                              <div className="" id="slide-trig5"  onClick={() => handleTriggerClick('custom-slide5')}>
                                <h6 className={`mt-2 mb-0 ${activeSlide === 'custom-slide5' ? 'active-color' : ''}`}>App</h6>
                                <h6 className={`${activeSlide === 'custom-slide5' ? 'active-color' : ''}`}> Development</h6>
                              </div>
                            </div>

                          </div>

                          <div className="col-6 mb-3">
                            <div className='slide-trig6'>
                              <h5 className='numbers fw-bold'>06</h5>
                              <div className="" id="slide-trig6"  onClick={() => handleTriggerClick('custom-slide6')}>
                                <h6 className={`mt-2 mb-0 ${activeSlide === 'custom-slide6' ? 'active-color' : ''}`}>Social Media</h6>
                                <h6 className={`${activeSlide === 'custom-slide6' ? 'active-color' : ''}`}> Marketing</h6>
                              </div>
                            </div>

                          </div>

                          <div className="col-6 mb-3">
                            <div className='slide-trig7'>
                              <h5 className='numbers fw-bold'>07</h5>
                              <div className="" id="slide-trig7"  onClick={() => handleTriggerClick('custom-slide7')}>
                                <h6 className={`mt-2 mb-0 ${activeSlide === 'custom-slide7' ? 'active-color' : ''}`}>Content</h6>
                                <h6 className={`${activeSlide === 'custom-slide7' ? 'active-color' : ''}`}>Writting</h6>
                              </div>
                            </div>
                          </div>

                         </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4 p-0">
                  <div className="custom-slider">

                      <div className={`slide custom-slide1 d-flex justify-content-center flex-column  ${activeSlide === 'custom-slide1' ? 'active' : ''}`}>
                          <div className="">
                            <img src="static/images/icons/1.svg" alt="" className='img-fluid mx-auto' width="100px"></img>
                          </div>

                          <div className='mt-5'>
                              <h4 className='text-white'>Graphic Designing</h4>
                          </div>

                          <div className='mt-5'>
                              <p className='text-white fs-14'>Our graphic design service transforms your vision into captivating, high-quality designs that reflect your brand identity. From logos to web graphics, our skilled team delivers professional results using the latest tools and trends. We work closely with you from concept to completion, ensuring designs that exceed your expectations and make your brand stand out.</p>
                          </div>
                      </div>

                      <div className={`slide custom-slide2 d-flex justify-content-center flex-column  ${activeSlide === 'custom-slide2' ? 'active' : ''}`}>
                          <div className="">
                            <img src="static/images/icons/4.svg" alt="" className='img-fluid mx-auto' width="100px"></img>
                          </div>

                          <div className='mt-5'>
                              <h4 className='text-white'>Digital Marketing</h4>
                          </div>

                          <div className='mt-5'>
                              <p className='text-white fs-14'>Our digital marketing service helps your business grow with data-driven strategies that increase visibility, engagement, and conversions. From SEO to PPC, we create tailored campaigns that deliver measurable results, driving your brand's online success.</p>
                          </div>
                      </div>

                      <div className={`slide custom-slide3 d-flex justify-content-center flex-column  ${activeSlide === 'custom-slide3' ? 'active' : ''}`}>
                          <div className="">
                            <img src="static/images/icons/3.svg" alt="" className='img-fluid mx-auto' width="100px"></img>
                          </div>

                          <div className='mt-5'>
                              <h4 className='text-white'>Website Designing</h4>
                          </div>

                          <div className='mt-5'>
                              <p className='text-white fs-14'>Our web design service creates visually appealing and user-friendly websites that reflect your brand's identity. We focus on intuitive layouts, responsiveness, and aesthetics to ensure your site stands out and provides an exceptional user experience.</p>
                          </div>
                      </div>

                      <div className={`slide custom-slide4 d-flex justify-content-center flex-column  ${activeSlide === 'custom-slide4' ? 'active' : ''}`}>
                          <div className="">
                            <img src="static/images/icons/2.svg" alt="" className='img-fluid mx-auto' width="100px"></img>
                          </div>

                          <div className='mt-5'>
                              <h4 className='text-white'>Website Development</h4>
                          </div>

                          <div className='mt-5'>
                              <p className='text-white fs-14'>Our website development service builds fast, reliable, and secure websites, tailored to your business needs. Whether it’s an e-commerce platform or a corporate site, we create robust solutions that enhance functionality and drive success.</p>
                          </div>
                      </div>


                      <div className={`slide custom-slide5 d-flex justify-content-center flex-column  ${activeSlide === 'custom-slide5' ? 'active' : ''}`}>
                          <div className="">
                            <img src="static/images/icons/5.svg" alt="" className='img-fluid mx-auto' width="100px"></img>
                          </div>

                          <div className='mt-5'>
                              <h4 className='text-white'>App Development</h4>
                          </div>

                          <div className='mt-5'>
                              <p className='text-white fs-14'>Our app development service brings your ideas to life with custom-built, user-friendly mobile and web applications. We focus on functionality, design, and performance, ensuring a seamless experience for users across all platforms.</p>
                          </div>
                      </div>

                      <div className={`slide custom-slide6 d-flex justify-content-center flex-column  ${activeSlide === 'custom-slide6' ? 'active' : ''}`}>
                          <div className="">
                            <img src="static/images/icons/6.svg" alt="" className='img-fluid mx-auto' width="100px"></img>
                          </div>

                          <div className='mt-5'>
                              <h4 className='text-white'>Social Media Marketing</h4>
                          </div>

                          <div className='mt-5'>
                              <p className='text-white fs-14'>Our social media marketing service helps your brand connect with its audience through engaging content and strategic campaigns. We manage your social presence across platforms, driving engagement, and fostering community growth.</p>
                          </div>
                      </div>


                      <div className={`slide custom-slide7 d-flex justify-content-center flex-column  ${activeSlide === 'custom-slide7' ? 'active' : ''}`}>
                          <div className="">
                            <img src="static/images/icons/7.svg" alt="" className='img-fluid mx-auto' width="100px"></img>
                          </div>

                          <div className='mt-5'>
                              <h4 className='text-white'>Content Writting</h4>
                          </div>

                          <div className='mt-5'>
                              <p className='text-white fs-14'>Our content writing service delivers high-quality, engaging content that aligns with your brand voice. From blogs to marketing copy, we craft impactful messaging that captivates your audience and enhances your brand’s communication.</p>
                          </div>
                      </div>




                  </div>
              </div>
            </div>
        </section>


    </>
  )
}

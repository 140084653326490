import React from 'react';
import { useState } from 'react';
import Portfolio from './Portfolio';
import Website from './Website';



export default function Gallery() {
    let Image1 = 'static/images/portfolio/graphic/1.webp';
    let Image2 = 'static/images/portfolio/graphic/2.webp';
    let Image3 = 'static/images/portfolio/graphic/3.webp';
    let Image4 = 'static/images/portfolio/logo/1.webp';
    let Image5 = 'static/images/portfolio/logo/2.webp';
    let Image6 = 'static/images/portfolio/logo/3.webp';
    
    
    const [activeLink, setActiveLink] = useState(0); // State to track active link index

    const handleClick = (index) => {
      setActiveLink(index); // Update active link index
    };
    
      
    const change1 = ()=>{
      
      handleClick(0);
    }

    const change2 = ()=>{
        
        handleClick(1);
  }

  const change3 = ()=>{
   
    handleClick(2);

}

const change4 = ()=>{
  
  handleClick(3);

}
 
    return (
    <>

        <section className="padding">
            <div className="container">
                <div className='text-md-start text-center'>
                  <h1 className="fw-bold">PortFolio</h1>
                  <p className="lined2"></p>
                </div>

                <ul className='ul my-5 text-md-start text-center ul-pill'>
                  <li><span className={`gallery ${activeLink === 0 ? 'active-gallery' : ''}`} onClick={change1}>All</span></li>
                  <li><span className={`gallery ${activeLink === 1 ? 'active-gallery' : ''}`} onClick={change2}>Logo Design</span></li>
                  <li><span className={`gallery ${activeLink === 2 ? 'active-gallery' : ''}`} onClick={change3} >Graphic Design</span></li>
                  <li><span className={`gallery ${activeLink === 3 ? 'active-gallery' : ''}`} onClick={change4}>Website</span></li>
                </ul>

                {activeLink === 0 && 
                <>
                <Portfolio
                heading="" 
                name1="Flyer Design" 
                link1={Image1}
                image1={Image1}
                name2="Roll-Up Banner Design"
                link2={Image2}
                image2={Image2}
                name3="Banner Design"
                link3={Image3}
                image3={Image3}
                 />
                <Portfolio 
                heading=""
                name1="MPA Travel Logo"
                link1={Image4}
                image1={Image4}
                name2="Herbie Logo"
                link2={Image5} 
                image2={Image5} 
                name3="EndoSurge Logo"
                link3={Image6}
                image3={Image6} />
                
                <Website heading="" 
                link1="https://portfolio.mediaproductionart.com/big%20red%20jelly/index.html" 
                image1="static/images/portfolio/website/1.webp"
                name1="Media Red Jelly"
                link2="https://portfolio.mediaproductionart.com/Logo%20design%20Valley/index.html" 
                image2="static/images/portfolio/website/2.webp"
                name2="Logo Design Media"
                link3="https://portfolio.mediaproductionart.com/law2/index.html"
                image3="static/images/portfolio/website/3.webp"
                name3="Law Firm 2"/>
                </>

                }

              


                {activeLink === 1 && 
                
                <Portfolio 
                heading=""
                name1="MPA Travel Logo"
                link1={Image4}
                image1={Image4}
                name2="Herbie Logo"
                link2={Image5} 
                image2={Image5} 
                name3="EndoSurge Logo"
                link3={Image6}
                image3={Image6} />

                
                }

                {activeLink === 2 && 
                  <Portfolio
                  heading="" 
                  name1="Flyer Design" 
                  link1={Image1}
                  image1={Image1}
                  name2="Roll-Up Banner Design"
                  name3="Banner Design"
                  link2={Image2}
                  image2={Image2}
                  link3={Image3}
                  image3={Image3}
                   />
                }

                {activeLink === 3 &&  
                <Website heading="" 
                link1="https://portfolio.mediaproductionart.com/LogodesignValley/index.html" 
                image1="static/images/portfolio/website/2.webp"
                name1="Logo Design Media"
            
                link2="https://portfolio.mediaproductionart.com/law2/index.html"
                image2="static/images/portfolio/website/3.webp"
                name2="Law Firm 2"/>
                }
                

               

                

              
            
            </div>

        </section>


    </>
  )
};



import React from 'react'
import Portfolio from './Portfolio'



export default function Designpf(props) {
  return (
    <>
        <section className="container">
              <div className="graphic-banner">
                  <h1 className='text-center fs-1'>Portfolio - Graphic Designing</h1>
              </div>
        
        </section>
    <section className='padding'>
        <div className="container ">
           
            <Portfolio heading="" link1="static/images/portfolio/graphic/1.webp" image1="static/images/portfolio/graphic/1.webp" name1="Flyer Design" link2="static/images/portfolio/graphic/2.webp" image2="static/images/portfolio/graphic/2.webp" link3="static/images/portfolio/graphic/3.webp" name2="Roll-Up Banner Design" image3="static/images/portfolio/graphic/3.webp" name3="Banner Design"/>

            <Portfolio heading="" link1="static/images/portfolio/graphic/4.webp" image1="static/images/portfolio/graphic/4.webp" name1="Business Card Design" link2="static/images/portfolio/graphic/5.webp" image2="static/images/portfolio/graphic/5.webp" link3="static/images/portfolio/graphic/6.webp" name2="Presentation Folder Design" image3="static/images/portfolio/graphic/6.webp" name3="Book Ad Design"/>

            <Portfolio heading="" link1="static/images/portfolio/graphic/7.webp" image1="static/images/portfolio/graphic/7.webp" name1="Presentation Folder Design" link2="static/images/portfolio/graphic/8.webp" image2="static/images/portfolio/graphic/8.webp" link3="static/images/portfolio/graphic/9.webp" name2="Post Design" image3="static/images/portfolio/graphic/9.webp" name3="Pouch Design"/>
              <div className='mt-5 text-center'>
                    <a href="https://portfolio.mediaproductionart.com/img/Media%20Portfoilo_Design%202023_Final%20With%20Website.pdf" rel='noreferrer' target='_blank' className='btn btn-1'>See More <i className="fa-solid fa-arrow-right"></i></a>        
                </div>
        </div>
    </section>
    </>
  )
}

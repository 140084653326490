import React from 'react'
import Portfolio from './Portfolio'




export default function Packpf(props) {
  return (
    <>
        <section className="packing-banner">
            <div className="container">
                <h1 className='text-center fs-1'>Portfolio - Packaging Designing</h1>
            </div>
        </section>

        <section className='padding'>
            <div className="container">
              
                <Portfolio
                heading="" 
                name1="KRATOM Pouch Design" 
                link1="static/images/portfolio/packaging/1.webp"
                image1="static/images/portfolio/packaging/1.webp"
                name2="CBD Bottle Design"
                link2="static/images/portfolio/packaging/2.webp"
                image2="static/images/portfolio/packaging/2.webp"
                name3="Jars Design"
                link3="static/images/portfolio/packaging/3.webp"
                image3="static/images/portfolio/packaging/3.webp"
                 />

                <Portfolio
                heading="" 
                name1="Amour Design" 
                link1="static/images/portfolio/packaging/4.webp"
                image1="static/images/portfolio/packaging/4.webp"
                name2="Black Buddha Pouch Design"
                link2="static/images/portfolio/packaging/5.webp"
                image2="static/images/portfolio/packaging/5.webp"
                name3="Black Buddha Bottle Design"
                link3="static/images/portfolio/packaging/6.webp"
                image3="static/images/portfolio/packaging/6.webp"
                 />

                <Portfolio
                heading="" 
                name1="Medicine Packaging Design" 
                link1="static/images/portfolio/packaging/7.webp"
                image1="static/images/portfolio/packaging/7.webp"
                name2="Vap Packaging Design"
                link2="static/images/portfolio/packaging/8.webp"
                image2="static/images/portfolio/packaging/8.webp"
                name3="ABWE Packaging Design"
                link3="static/images/portfolio/packaging/9.webp"
                image3="static/images/portfolio/packaging/9.webp"
                 />
               <div className='mt-5 text-center'>
                    <a href="https://portfolio.mediaproductionart.com/img/Media%20Portfoilo_Design%202023_Final%20With%20Website.pdf" rel='noreferrer' target='_blank' className='btn btn-1'>See More <i className="fa-solid fa-arrow-right"></i></a>        
                </div>

            </div>
        </section>

    </>
  )
}

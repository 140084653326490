import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import About from "./Components/About";
import Ball from "./Components/Ball";
import Services from "./Components/Services";
import Folios from "./Components/Folios";
import Designpf from "./Components/Designpf";
import Logopf from "./Components/Logopf";
import Webpf from "./Components/Webpf";
import Packpf from "./Components/Packpf";
import Contact from "./Components/Contact";
import Packages from "./Components/Packages";
import Wpicon from './Components/Wpicon';
import Career from './Components/Career';

// document.querySelector('.body').style.overflow = 'hidden';



  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'instant' // Add smooth behavior
    });

      document.addEventListener('mousemove', function(event) {
        var ball = document.querySelector('.client-ball');
        var ball2 = document.querySelector('.client-parent');
        ball.style.cssText = ball2.style.cssText = `left: ${event.clientX}px; top: ${event.clientY}px`;
        ball.style.transition = '0.15s ease';
        ball2.style.transition = '0.1s ease';
      });

      window.addEventListener('scroll', function(){
        var footer = document.querySelector('.footer');
        var ball = document.querySelector('.client-ball');
        var ball2 = document.querySelector('.client-parent');
        
        if(this.scrollY > footer.offsetTop){
          ball.style.backgroundColor='#fff';
          ball2.style.borderColor='#fff';
          document.addEventListener('mousemove', function(event){
            ball.style.backgroundColor='#fff';
            ball2.style.borderColor='#fff';
          });
        }else{
          ball.style.backgroundColor='#0f84c6';
          ball2.style.borderColor='#0f84c6';
          document.addEventListener('mousemove', function(event){
            ball.style.backgroundColor='#0f84c6';
            ball2.style.borderColor='#0f84c6';
          });
        }
        
     });
    




      
      
    }, [pathname]);

    return null;
  }

  

 


function App() {

  window.addEventListener('load', function(){
    const loader = document.querySelector('.loader');
    loader.style.opacity = '0';
    loader.style.visibility = 'hidden';
    loader.style.transition = 'all 0.5s linear';
  });



   
  return (
    <>
     <Router>
      <ScrollToTop/>    
      <Navbar />
      <Wpicon />
      <Ball/>
      <Routes>
        <Route path="/" element={<Home />} key="home" />
        <Route path="/about" element={<About title="About Us" />} key="about" />
        <Route path="/services" element={<Services title="Service page" />} />
        <Route path="/portfolio" element={<Folios title="Our Portfolio" url="static/images/portfolio/all.webp" />} />
        <Route path="/web-development" element={<Webpf title="Website Development Portfolio" url="static/images/portfolio/website.webp" />} />
        <Route path="/graphic-design" element={<Designpf title="Graphic Design Portfolio" url="static/images/portfolio/logo.webp" />} />
        <Route path="/logo-design" element={<Logopf title="Logo Design Portfolio" url="static/images/portfolio/logo.webp" />} />
        <Route path="/packaging-design" element={<Packpf title="Packaging Design Portfolio" url="static/images/portfolio/packing.webp" />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
    </>
    
    
  );
};



export default App;

import React, { useState } from 'react'
import Banner from './Banner'
import Cards from './Cards'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import Gallery from './Gallery';
import { Link } from 'react-router-dom';
import Servicecard from './Servicecard';
import Testimonials from './Testimonials';



export default function Home() {
  const [Counter,setCounter] = useState(false)
  
  return (
    <>
    <Banner/>
     {/* banner ends here..................   */}
        

    

     {/* section starts here....................   */}
              <section className="padding " >
                <div className="container">
                  <div className="row">

                    <div className="col-lg-6 mt-md-0 mt-3 text-center">
                      <img src="static/images/about/MPA Website_Design-03.webp" alt=""  loading="lazy"
                      className='img-fluid'></img>
                    </div>

                    <div className="col-lg-6 text-md-start text-center my-auto">
                      
                      
                      <h2 className="fw-bold">
                        Who We Are  
                      </h2>
                      <p className="mt-2 text-justify">Media Production Art is a graphic and website design company with over a decade of experience in creating a brand identity for our clients. We use creative strategies to develop all aspects of a client’s brand, from website development to logo design and Digital Marketing solutions.</p>
                      
                      <div className="row">
                          <div className="col-md-6 ">
                          <div className="d-flex align-items-start">

                            <div className='about-icon-ball d-flex justify-content-center align-items-center '>
                              <img src="static/images/world.svg" alt="" className='img-fluid' width={'30px'}></img>
                            </div>
                            <div className='my-md-auto mt-3 mx-3 text-start'>
                              <h6>Global Experience</h6>
                              <p className='fs-14'>delivers innovative media production solutions with cutting-edge technology </p>
                            </div>
                          </div>
                          </div>
                          <div className="col-md-6 mt-md-0 mt-3 ">
                            <div className="d-flex align-items-start">
                            <div className='about-icon-ball d-flex justify-content-center align-items-center '>
                              <img src="static/images/setting.svg" alt="" className='img-fluid' width={'30px'}></img>
                            </div>
                            <div className='my-md-auto mt-3 mx-3 text-start'>
                              <h6>High Quality Result</h6>
                              <p className='fs-14'>We ensures high-quality results through advanced technology and creativity</p>
                            </div>
                            </div>

                          </div>
                      </div>

                      <div className="mt-4 text-start">
                       
                          <Link to="/about" className="btn-1 btn my-auto">Read More</Link>
                       
                       
                      </div>
                    </div>
                  
                  

                  

                  </div>
                </div>
                      
              </section>
     {/* section end here....................   */}
          <Servicecard/>


      

        <ScrollTrigger onEnter={()=> setCounter(true)} onExit={()=> setCounter(false)}>
        <section className='padding bg-blue-image' id="counter">
          <div className="container">
            <div className='py-5 text-center mt-4'>
              <h1 className="fw-bold fs-1 text-white">Grow with award-winning support <br></br>and a thriving community behind you.</h1>
              <p className="mt-4 text-white">Media Production Art's scale and capability has grown over a long history of successfully serving our customers.</p>
            </div>
            <div className="row">
              <div className="col-md-4 text-center">
                 {Counter && <h1 className='fw-bold fs-1 text-white'><CountUp start={1000} end={15725} duration={3} /><sup>+</sup></h1>}
                 <p className='text-white'>Projects Done</p>
              </div>
              <div className="col-md-4 text-center mt-md-0 mt-4">
                 {Counter && <h1 className='fw-bold fs-1 text-white'><CountUp start={0} end={100} duration={3} /><sup>%</sup></h1>}
                 <p className='text-white'>Success Rate</p>

              </div>
              <div className="col-md-4 text-center mt-md-0  mt-4">
                 {Counter && <h1 className='fw-bold fs-1 text-white'><CountUp start={0} end={73623} duration={3} /><sup>+</sup></h1>}
                 <p className='text-white'>Happy Clients</p>

              </div>
            </div>

          </div>
        </section>
        </ScrollTrigger>


        
        <section className='padding '>
          <div className="container py-5">
              <div className="row">
                <div className="col-md-6">
                    <img src="static/images/team2.png" alt=""  loading="lazy" className="img-fluid"></img>
                </div>
                <div className="col-md-6 my-auto px-md-4 mt-md-auto mt-4 text-md-start text-center">
                    <h2 className="fw-bold lined2">We achieve your goals effectively </h2>
                    <p className="mt-5">
                    At Media Production Art, our mission is to achieve your goals effectively. With a commitment to excellence and a focus on results-driven solutions, we empower our clients to reach new heights of success.
                    </p>
                   
                    <div className='mt-5'>
                        <Link to="/about" className="btn btn-1 fs-14">Learn More</Link>
                        <Link to="/about" className="mx-4 fw-bold fs-14" style={{'borderBottom':'1px solid #000'}}>How we work</Link>
                    </div>
                    <div className="mt-5">
                        <h1>15<sup>+</sup></h1>
                        <p className='mb-0 text-dark'>Years of Hall of Fame </p>
                        <p>& Experience</p>
                    </div>
                </div>  
              </div>
          </div>
        </section>

        {/* section...........................  */}
          <Gallery/>
        {/* section...........................  */}

        <Testimonials/>



        
      {/* section ends here..............  */}

    </>
  )
}

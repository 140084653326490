import React from 'react'

export default function Ball() {
  

  return (
    <>
    <div className="client-parent">
    </div>
    <div className="client-ball">
        
    </div>
    </>
  )
}

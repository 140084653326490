import React, {useState} from 'react'
import { Link } from 'react-router-dom';



export default function Packages() {
  
    const [activeLink, setActiveLink] = useState(0); // State to track active link index

    const handleClick = (index) => {
      setActiveLink(index); // Update active link index
    };

    const change1 = ()=>{  
        handleClick(0);
    }
    const change2 = ()=>{            
        handleClick(1);
    }
    const change3 = ()=>{
        handleClick(2);
    }
    

  return (
    <>
      {/* popup code start ...........................  */}
      {/* <div className="pop-up padding">
        <div className="container">
        <div className='text-end'>
          <p className='close-popup' onClick={popupOff}>
            <i className="fa-solid fa-xmark fs-4 text-white"></i>
          </p>
        </div>
            <div className='form bg-white mx-auto'>
                    <div>
                        <h3 className='fw-bold'>We’ll Be Glad To Assist You!</h3>
                       
                    </div>
                        <form ref={form} action="" className='' onSubmit={sendEmail}>
                        <div className='mt-3'>
                                <input type="text" required className='input w-100' placeholder="Name" name="from_name" />
                            </div>
                            <div className='mt-3'>
                                <input type="tel"  className='input w-100' placeholder="Phone Number" name="from_phone"/>
                            </div>
                            <div className='mt-3'>
                                <input type="email" required className='input w-100' placeholder="Email Address" name="from_email" />
                            </div>
                            
                            <div className='mt-3'>
                                <textarea type="text" required className='input w-100 ' rows="5" placeholder="Your Message" name="message" />
                            </div>
                            <div className='text-center'>
                                <button className='btn btn-1 mt-3 ' type="submit">Send Message</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
              </div> */}

      {/* popup code ends..............................  */}
     
      <section className="padding package-banner">
        <div className="container">
            <h1 className='text-center'>
                Media Production Art - Packages
            </h1>
            <div className="row mt-5 col-lg-8 mx-auto justify-content-between align-items-star text-lg-start text-center">
              <h5 className="fw-bold col-lg-3 ">
                Our Reasonable Packages 
              </h5>
              <p className="border-custom mt-3 col-lg-2 mx-lg-0 mx-auto"></p>
              <p className='fw-bold text-secondary col-lg-7 '>
              "Elevate your digital presence with our tailored packages. From web development to graphic design, we offer comprehensive solutions for your business needs."</p>
            </div>
           
          
        </div>
      </section>

      <section className="padding ">
        <div className="container">
            <h3 className="text-center fw-bold mb-0">Explore Flexible Pricing Plans
            </h3>
            <h3 className="mb-5 text-center fw-bold">Choose a Service
            </h3>
            <ul className='package-ul my-5 text-center row '>
                  <li className='col-md-4'><button className={`package-link ${activeLink === 0 ? 'active-package' : ''}`} onClick={change1}>All</button></li>
                  <li className='col-md-4 mt-md-0 mt-5'><button className={`package-link ${activeLink === 1 ? 'active-package' : ''}`} onClick={change2} >Graphic Design</button></li>
                  <li className='col-md-4 mt-md-0 mt-5'><button className={`package-link ${activeLink === 2 ? 'active-package' : ''}`} onClick={change3}>Website</button></li>
                </ul>

            {activeLink === 0 &&
                <>
                <div className="row ">
                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                       
                       <div className="package mx-auto pb-4 mt-4 bg-white">
                         <div className="row">
                             <div className="col-8 text-blue mt-4 ">
                               <h4 className="fw-bold text-blue">Media Bronze</h4>
                              
                               
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="fs-14 text-dark mt-4">
                           <li>Get 6 Custom Logo design concepts</li>
                           <li>1 Dedicated Brand Designer</li>
                           <li>Get Rounds of Mockups</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
              
                         <div className="mt-4 text-xl-start text-center">
                           <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                          
                         </div>
                       </div>
                    </div>

                    <div className="col-lg-4 mt-lg-0 mt-4 mt-md-0 ">
                       
                       <div className="package mx-auto pb-4 mt-4 bg-blue-gradient">
                         <div className="row">
                             <div className="col-8 mt-4">
                               <h4 className="fw-bold text-white">Media Silver</h4>
                              
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">42%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="fs-14 text-white mt-4">
                           <li>Get 10 Logo design concepts</li>
                           <li>3 Dedicated Brand Designer</li>
                           <li>Get Rounds of Mockups Revision (Up to 8)</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Business Card Design (JPG Format)</li>
                           <li>Letterhead Design (JPG Format)</li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
     
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-2 m-2 px-3">Order Now</Link>
                           
                         </div>
                       </div>
                     </div>

                     <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                       <div className="package mx-auto pb-4 mt-4 ">
                         <div className="row">
                             <div className="col-8 text-blue mt-4">
                               <h4 className="fw-bold text-blue">Media Gold</h4>
                             
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">50%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="fs-14 text-dark mt-4">
                           <li>15 Logo design concepts</li>
                           <li>1 Landing Page Design Concept (Up to 2 Revisions)</li>
                           <li>4 Dedicated Designer</li>
                           <li>Rounds Of Revision (Up to 7)</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Business Card Design (JPG Format)</li>
                           <li>Letterhead Design (JPG Format)</li>
                           <li>Envelope Design (JPG Format)</li>
                           <li>Icon Design (ICO Format)</li>
                           <li>Social Header Design (JPG, PNG & PSD Format)</li>
                           <li>Email Signature Design (JPG, PNG & PSD Format)</li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                           
                         </div>
                        
                       </div>
                     </div>

    
                     <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 ">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Basic - 1</h4>
                            
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="fs-14 text-dark mt-4">
                          <li>5 Pages Template Based Website</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>5 Logo Design Concepts</li>
                          <li>4 Dedicated Designers</li>
                          <li>Rounds of Revision (Up to 3)</li>
                          <li>Raster Files Format (JPG, PNG & PSD) </li>
                          <li>Vector Files Format (AI, EPS & PDF) </li>
                          <li>Business Card Design (JPG Format)</li>
                          <li>Satisfaction Guaranteed</li>
                          <li>Unique Design Guaranteed</li>
                          <li>Money Back Gaurantee</li>
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                         
                        </div>
                       
                      </div>
                    </div>
                   

                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 bg-blue-gradient">
                        <div className="row">
                            <div className="col-8  mt-4">
                              <h4 className="fw-bold text-white">Media Basic - 2</h4>
                             
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="fs-14 text-white mt-4">
                          <li>5 Unique Design Pages</li>
                          <li>1 Basic Contact / Inquiry Form</li>
                          <li>Responsive (Mobile / Tablet)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free 3 Stock Photos</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 3 Months Web Hosting</li>
                          <li>FREE 1GB Web Hosting Space</li>
                          <li>3 Months of Free Domain Registration</li>
                          
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-2 m-2 px-3">Order Now</Link>
                          </div>
                       
                      </div>
                    </div>


                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 ">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Pro - 1</h4>
                             
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="fs-14 text-dark mt-4">
                          <li>8 Pages Template Based Website</li>
                          <li>1 Basic Contact / Inquiry Form</li>
                          <li>Responsive (Mobile / Tablet)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free 5 Stock Photos</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 6 Months Web Hosting</li>
                          <li>FREE 3GB Web Hosting Space</li>
                          <li>6 Months of Free Domain Registration</li>
                          
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                         
                        </div>
                       
                      </div>
                    </div>







                    </div>

                    </>
                    }


              {activeLink === 1 &&
                <>
                <div className="row ">
                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                       
                       <div className="package mx-auto pb-4 mt-4 ">
                         <div className="row">
                             <div className="col-8 text-blue mt-4">
                               <h4 className="fw-bold text-blue">Media Bronze</h4>
                              
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="fs-14 text-dark mt-4">
                           <li>Get 6 Custom Logo design concepts</li>
                           <li>1 Dedicated Brand Designer</li>
                           <li>Get Rounds of Mockups</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
     
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                       
                           
                         </div>
                       </div>
                    </div>

                    <div className="col-lg-4 mt-lg-0 mt-4 mt-md-0 ">
                       
                       <div className="package mx-auto pb-4 mt-4 bg-blue-gradient">
                         <div className="row">
                             <div className="col-8  mt-4">
                               <h4 className="fw-bold text-white">Media Silver</h4>
                               
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">42%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="fs-14 text-white mt-4">
                           <li>Get 10 Logo design concepts</li>
                           <li>3 Dedicated Brand Designer</li>
                           <li>Get Rounds of Mockups Revision (Up to 8)</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Business Card Design (JPG Format)</li>
                           <li>Letterhead Design (JPG Format)</li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
     
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-2 m-2 px-3">Order Now</Link>
                            
                         </div>
                       </div>
                     </div>

                     <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                       <div className="package mx-auto pb-4 mt-4 ">
                         <div className="row">
                             <div className="col-8 text-blue mt-4">
                               <h4 className="fw-bold text-blue">Media Gold</h4>
                              
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">50%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="fs-14 text-dark mt-4">
                           <li>15 Logo design concepts</li>
                           <li>1 Landing Page Design Concept (Up to 2 Revisions)</li>
                           <li>4 Dedicated Designer</li>
                           <li>Rounds Of Revision (Up to 7)</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Business Card Design (JPG Format)</li>
                           <li>Letterhead Design (JPG Format)</li>
                           <li>Envelope Design (JPG Format)</li>
                           <li>Icon Design (ICO Format)</li>
                           <li>Social Header Design (JPG, PNG & PSD Format)</li>
                           <li>Email Signature Design (JPG, PNG & PSD Format)</li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                            
                         </div>
                        
                       </div>
                     </div>


                    


                     </div>
                     </>
                     }



              {activeLink === 2 &&
                <>
                <div className="row ">
               
                <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 ">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Basic - 1</h4>
                            
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="fs-14 text-dark mt-4">
                          <li>5 Pages Template Based Website</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>5 Logo Design Concepts</li>
                          <li>4 Dedicated Designers</li>
                          <li>Rounds of Revision (Up to 3)</li>
                          <li>Raster Files Format (JPG, PNG & PSD) </li>
                          <li>Vector Files Format (AI, EPS & PDF) </li>
                          <li>Business Card Design (JPG Format)</li>
                          <li>Satisfaction Guaranteed</li>
                          <li>Unique Design Guaranteed</li>
                          <li>Money Back Gaurantee</li>
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                           
                        </div>
                       
                      </div>
                    </div>
                   

                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 bg-blue-gradient">
                        <div className="row">
                            <div className="col-8 mt-4">
                              <h4 className="fw-bold text-white">Media Basic - 2</h4>
                             
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="fs-14 text-white mt-4">
                          <li>5 Unique Design Pages</li>
                          <li>1 Basic Contact / Inquiry Form</li>
                          <li>Responsive (Mobile / Tablet)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free 3 Stock Photos</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 3 Months Web Hosting</li>
                          <li>FREE 1GB Web Hosting Space</li>
                          <li>3 Months of Free Domain Registration</li>
                          
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-2 m-2 px-3">Order Now</Link>
                     
                        </div>
                       
                      </div>
                    </div>


                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 ">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Pro - 1</h4>
                              
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="fs-14 text-dark mt-4">
                          <li>8 Pages Template Based Website</li>
                          <li>1 Basic Contact / Inquiry Form</li>
                          <li>Responsive (Mobile / Tablet)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free 5 Stock Photos</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 6 Months Web Hosting</li>
                          <li>FREE 3GB Web Hosting Space</li>
                          <li>6 Months of Free Domain Registration</li>
                          
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                       
                        </div>
                       
                      </div>
                    </div>



                 

                     </div>
                     </>
                     }  



        </div>
      </section>
    </>
  )
}

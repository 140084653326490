import React from 'react'
import { Link } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';
export default function Navbar() {
  const location = useLocation();
  
  window.addEventListener('scroll',function(){
    var navbar = document.querySelector('.scrolltop');
      if(this.scrollY > 160){
        navbar.style.visibility = 'visible';
        navbar.style.bottom = '25px';
        navbar.style.transition = 'all 0.3s ease-in-out';
      }else{
        navbar.style.visibility = 'hidden';
        navbar.style.bottom = '-100px';
        navbar.style.transition = 'all 0.3s ease-in-out';
      }
      var footer = this.document.querySelector('.footer');
      if(this.scrollY >= footer.offsetTop){
        navbar.style.visibility = 'hidden';
        navbar.style.bottom = '-100px';
        navbar.style.transition = 'all 0.3s ease-in-out';
    }
    navbar.addEventListener('click', function(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });



  });



  return (
    <>  
      <section className='loader'>
        <div className="container mx-auto">
        <div class="ping mx-auto"></div>
        </div>
      </section>

    
     <section>
      <div className="scrolltop">
        <i class="fa-solid fa-arrow-up"></i>
      </div>
     </section>


      <header className="p-2 d-lg-block d-none ">
        <div className="container">
            <div className="row justify-content-around align-items-center"> 
                <div className="col-md-8 header-text">
                    <p className="my-auto"><span className="">WhatsApp/Call:</span> <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className='header-link' target="_blank">+923133876798</Link> <span className="">Or</span> <span className=""> Email Us:</span> <Link className='header-link' to="mailto:media.production.art@gmail.com" target="_blank">media.production.art@gmail.com</Link></p>
                </div>
                <div className="col-md-4 my-auto">
                <div className="text-end header-icons">
                    <Link to="https://www.linkedin.com/in/mediaproductionart/" target="_blank"><i className="fa-brands fa-linkedin "></i></Link>
                    <Link to="https://www.instagram.com/mediaproductionart/" target="_blank"><i className="fa-brands fa-instagram"></i></Link>
                    <Link to="https://www.facebook.com/mediaproductionart" target="_blank"><i className="fa-brands fa-facebook-f"></i></Link>
                    <Link to="https://www.youtube.com/@mediaproductionarteducation" target="_blank"><i className="fa-brands fa-youtube "></i></Link>
                    <Link to="https://www.tiktok.com/@mediaproductionart" target="_blank"><i className="fa-brands fa-tiktok"></i></Link>
                </div>
                </div>
            </div>
        </div>
    </header>
    
    {/* header ends here..................   */}

    {/* navigation starts here......................   */}
          <nav className="navbar navbar-expand-xl navbar-light">
            <div className="container">
              <NavLink className="navbar-brand" to="/"><img src="static/images/Logo.webp" alt=""  loading="lazy" className="img-fluid"></img></NavLink>

                {/* Monitor menu ........................  */}

                <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto monitor-menu">
                  
                  <li className="nav-item link">
                    <NavLink className={location.pathname === '/' ? 'nav-link active' : 'nav-link'} to="/">Home</NavLink>
                  </li>
                    <li className="nav-item link">
                    <NavLink className={location.pathname === '/about' ? 'nav-link active' : 'nav-link'} to="/about">About Us</NavLink>
                  </li>
                  <li className="nav-item link">
                    <NavLink className={location.pathname === '/services' ? 'nav-link active' : 'nav-link'} to="/services">Services</NavLink>
                  </li>
                  <li className="nav-item drop-toggle">
                    <NavLink className={location.pathname === '/portfolio' ? 'nav-link active' : 'nav-link'} to="/portfolio">
                      Portfolio <i className="fa-solid fa-angle-down my-auto" style={{fontSize:'14px'}}></i>
                    </NavLink>
                    <ul className="drop-menu" >
                      <li><NavLink className={location.pathname === '/web-development' ? 'drop-item active' : 'drop-item'} to="/web-development">Website Development</NavLink></li>
                      <li><NavLink className={location.pathname === '/graphic-design' ? 'drop-item active' : 'drop-item'} to="/graphic-design">Graphic Designing</NavLink></li>
                      <li><NavLink className={location.pathname === '/packaging-design' ? 'drop-item active' : 'drop-item'} to="/packaging-design">Packaging Designing</NavLink></li>
                      <li><NavLink className={location.pathname === '/logo-design' ? 'drop-item active' : 'drop-item'} to="/logo-design">Logo Designing</NavLink></li>
                    </ul>
                  </li>
                  <li className="nav-item link">
                    <NavLink className={location.pathname === '/packages' ? 'nav-link active' : 'nav-link'} to="/packages">Packages</NavLink>
                  </li>
                  <li className="nav-item link">
                    <NavLink className={location.pathname === '/career' ? 'nav-link active' : 'nav-link'} to="/career">Career</NavLink>
                  </li>
                  <li className="nav-item link">
                    <NavLink className="nav-link" to="https://edu.mediaproductionart.com/" target='_blank'>Education</NavLink>
                  </li>
                  <li className="nav-item link">
                    <NavLink className={location.pathname === '/contact' ? 'nav-link active' : 'nav-link'} to="/contact-us">Contact</NavLink>
                  </li>
                </ul>
                {/* Monitor menu ........................  */}


                {/* mobile menu ........................  */}
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto mobile-menu ">
                  <div className="text-end close d-xl-none d-block">
                    <NavLink className="close-menu closed"><i className="fa-solid fa-xmark fs-5"></i></NavLink>
                  </div>
                  
                  <li className="nav-item link">
                    <NavLink className="nav-link off-link" to="/">Home</NavLink>
                  </li>
                    <li className="nav-item link">
                    <NavLink className="nav-link off-link" to="/about">About Us</NavLink>
                  </li>
                  <li className="nav-item link">
                    <NavLink className="nav-link off-link" to="/services">Services</NavLink>
                  </li>
                  <li className="nav-item drop-toggle">
                    <NavLink className="nav-link link on-link text-dark">
                      Portfolio <i className="fa-solid fa-angle-down my-auto" style={{fontSize:'14px'}}></i>
                    </NavLink>
                    <ul className="drop-menu" >
                      <li><NavLink className="drop-item off-link" to="/portfolio">All </NavLink></li>
                      <li><NavLink className="drop-item off-link" to="/web-development">Website development</NavLink></li>
                      <li><NavLink className="drop-item off-link" to="/graphic-design">Graphic Design </NavLink></li>
                      <li><NavLink className="drop-item off-link" to="/packaging-design">Packaging Design</NavLink></li>
                      <li><NavLink className="drop-item off-link" to="/logo-design">Logo Design </NavLink></li>
                    </ul>
                  </li>
                  <li className="nav-item link">
                    <NavLink className="nav-link off-link" to="/packages">Packages</NavLink>
                  </li>
                  <li className="nav-item link">
                    <NavLink className="nav-link off-link" to="/career">Career</NavLink>
                  </li>
                  <li className="nav-item link">
                    <NavLink className="nav-link off-link" to="https://edu.mediaproductionart.com/" target='_blank'>Education</NavLink>
                  </li>
                  <li className="nav-item link">
                    <NavLink className="nav-link off-link" to="/contact-us">Contact</NavLink>
                  </li>
                </ul>
                {/* mobile menu ........................  */}


                <div className="d-flex ">
                    <NavLink className="mx-2 bars d-xl-none d-inline-flex ">
                      <img src="static/images/bar.svg" alt=""  loading="lazy" className="img-fluid" width="20px"></img>
                  </NavLink>
                  <NavLink to="/contact-us" className="btn btn-1 mt-md-0 mt-4 fw-bold d-lg-inline-flex d-none">
                        Get a Qoute
                  </NavLink>
                </div>
            
            </div>
          </nav>
          <div className="overlay"></div> 
    </>
  )
}

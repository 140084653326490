import React from 'react'
import Website from './Website'



export default function Webpf(props) {
  return (
    <>
    
        <section className="website-banner">

            <div className="container">
                <h1 className='text-center fs-1'>Portfolio - Website Development</h1>
            </div>
        </section>

        <section className='padding'>
            <div className="container">
                
                <Website heading="" link1="https://portfolio.mediaproductionart.com/law2/index.html" image1="static/images/portfolio/website/3.webp" name1="Law Firm 2" link2="https://portfolio.mediaproductionart.com/LogodesignValley/index.html" image2="static/images/portfolio/website/2.webp" name2="Logo Design Media" />
                
                <Website heading="" link1="https://portfolio.mediaproductionart.com/logoDesignNet/index.html" image1="static/images/portfolio/website/4.webp" name1="Logo Media" link2="https://portfolio.mediaproductionart.com/Lawfirm/index.html" image2="static/images/portfolio/website/5.webp" name2="Law Firm"/>

           
                <Website heading="" link1="https://www.roastedroseflorals.com/" image1="static/images/portfolio/website/10.webp" name1="Floral Studio - Wix" link2="https://www.leroysfloristflorida.net/" image2="static/images/portfolio/website/11.webp" name2="Leroy's Florist - Squarespace" />

                <div className='mt-5 text-center'>
                    <a href="https://portfolio.mediaproductionart.com/" rel='noreferrer' target='_blank' className='btn btn-1'>See More <i className="fa-solid fa-arrow-right"></i></a>        
                </div>
            </div>
        </section>

    </>
  )
}

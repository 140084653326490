import React from 'react'
import Portfolio from './Portfolio'
import Website from './Website'





export default function Folios(props) {
  return (
    <>
    <section className="padding portfolio-banner" >
        <div className="container text-center">
            <h1 className='lined'>Media Production Art - Portfolio</h1>
        </div>
    </section>

    <section className='padding'>
        <div className="container ">
            <h1 className='lined3 fs-1 text-center my-5'>Graphic Design Portfolio</h1>
            <Portfolio heading="" link1="static/images/portfolio/graphic/1.webp" image1="static/images/portfolio/graphic/1.webp" name1="Flyer Design" link2="static/images/portfolio/graphic/2.webp" image2="static/images/portfolio/graphic/2.webp" name2="Roll-Up Banner Design"/>
            <Portfolio heading="" link1="static/images/portfolio/graphic/4.webp" image1="static/images/portfolio/graphic/4.webp" name1="Business Card Design" link2="static/images/portfolio/graphic/5.webp" image2="static/images/portfolio/graphic/5.webp"  name2="Presentation Folder Design" />

            <Portfolio heading="" link1="static/images/portfolio/graphic/7.webp" image1="static/images/portfolio/graphic/7.webp" name1="Presentation Folder Design" link2="static/images/portfolio/graphic/8.webp" image2="static/images/portfolio/graphic/8.webp" name2="Post Design" />
            <div className='mt-5 text-center'>
                    <a href="https://portfolio.mediaproductionart.com/img/Media%20Portfoilo_Design%202023_Final%20With%20Website.pdf" rel='noreferrer' target='_blank' className='btn btn-1'>See More <i className="fa-solid fa-arrow-right"></i></a>        
                </div>
        </div>
    </section>

    <section className='padding'>
        <div className="container ">
            <h1 className='lined3 fs-1 text-center my-5'>Logo Design Portfolio</h1>
            <Portfolio heading="" link1="static/images/portfolio/logo/1.webp" image1="static/images/portfolio/logo/1.webp" name1="MPA Travel Logo" link2="static/images/portfolio/logo/2.webp" image2="static/images/portfolio/logo/2.webp" name2="Herbie's Logo" />

            <Portfolio heading="" link1="static/images/portfolio/logo/4.webp" image1="static/images/portfolio/logo/4.webp" name1="Kane Foundation Logo" link2="static/images/portfolio/logo/5.webp" image2="static/images/portfolio/logo/5.webp" name2="Black Buddha Logo" />

            <Portfolio heading="" link1="static/images/portfolio/logo/7.webp" image1="static/images/portfolio/logo/7.webp" name1="Juice Collection Logo" link2="static/images/portfolio/logo/8.webp" image2="static/images/portfolio/logo/8.webp" name2="Acres 4 Change Logo"/>
            <div className='mt-5 text-center'>
                    <a href="https://portfolio.mediaproductionart.com/img/Media%20Portfoilo_Design%202023_Final%20With%20Website.pdf" rel='noreferrer' target='_blank' className='btn btn-1'>See More <i className="fa-solid fa-arrow-right"></i></a>        
            </div>
        </div>
    </section>


    <section className='padding'>
        <div className="container ">
            <h1 className='lined3 fs-1 text-center my-5'>Packaging Design Portfolio</h1>
                <Portfolio
                heading="" 
                name1="KRATOM Pouch Design" 
                link1="static/images/portfolio/packaging/1.webp"
                image1="static/images/portfolio/packaging/1.webp"
                name2="CBD Bottle Design"
                link2="static/images/portfolio/packaging/2.webp"
                image2="static/images/portfolio/packaging/2.webp"
                
                 />

                <Portfolio
                heading="" 
                name1="Amour Design" 
                link1="static/images/portfolio/packaging/4.webp"
                image1="static/images/portfolio/packaging/4.webp"
                name2="Black Buddha Pouch Design"
                link2="static/images/portfolio/packaging/5.webp"
                image2="static/images/portfolio/packaging/5.webp"
               
                 />

                <Portfolio
                heading="" 
                name1="Medicine Packaging Design" 
                link1="static/images/portfolio/packaging/7.webp"
                image1="static/images/portfolio/packaging/7.webp"
                name2="Vap Packaging Design"
                link2="static/images/portfolio/packaging/8.webp"
                image2="static/images/portfolio/packaging/8.webp"
               
                 />

                <div className='mt-5 text-center'>
                    <a href="https://portfolio.mediaproductionart.com/img/Media%20Portfoilo_Design%202023_Final%20With%20Website.pdf" rel='noreferrer' target='_blank' className='btn btn-1'>See More <i className="fa-solid fa-arrow-right"></i></a>        
                </div>
        </div>
    </section>


    <section className='padding'>
        <div className="container">
            <h1 className='lined3 fs-1 text-center my-5'>Web Development Portfolio</h1>
                <Website heading="" link1="https://portfolio.mediaproductionart.com/big%20red%20jelly/index.html" image1="static/images/portfolio/website/1.webp" name1="Media Red Jelly Website - Custom" link2="https://portfolio.mediaproductionart.com/Logo%20design%20Valley/index.html" image2="static/images/portfolio/website/2.webp" name2="Logo Design Media Website - Custom" />
                
                <Website heading="" link1="https://portfolio.mediaproductionart.com/logo%20Design%20net/index.html" image1="static/images/portfolio/website/4.webp" name1="Logo Media Website - Custom" link2="https://portfolio.mediaproductionart.com/Law%20firm/index.html" image2="static/images/portfolio/website/5.webp"  name2="Law Firm Website - Custom" />

                <Website heading="" link1="https://portfolio.mediaproductionart.com/wp/resturant/" image1="static/images/portfolio/website/7.webp" name1="Restaurant Website - WordPress" link2="https://portfolio.mediaproductionart.com/wp/beauty/" image2="static/images/portfolio/website/8.webp" name2="Beauty Website - WordPress"/>

                <Website heading="" link1="https://www.roastedroseflorals.com/" image1="static/images/portfolio/website/10.webp" name1="Floral Studio - Wix" link2="https://www.leroysfloristflorida.net/" image2="static/images/portfolio/website/11.webp" name2="Leroy's Florist - Squarespace" />
       
                <div className='mt-5 text-center'>
                    <a href="https://portfolio.mediaproductionart.com/" rel='noreferrer' target='_blank' className='btn btn-1'>See More <i className="fa-solid fa-arrow-right"></i></a>        
                </div>
        </div>
    </section>
   
    

    </>
  )
}

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';



// import 'swiper/swiper.min.css';
import 'swiper/css';
import 'swiper/css/pagination';


// import './styles.css';
// import required modules
import {Autoplay, EffectFade , EffectSlide}from 'swiper/modules';




export default function Testimonials() {
  return (
    <>


    <section className='testimonial-parent padding'>
      <div className="blur-effect1"></div>
      <div className="container-fluid">
          <h1 className='text-center mb-5 pb-3'>Success Stories From Clients</h1>
    
    
           
        <Swiper
                      slidesPerView={3}
                      autoplay={{
                        delay: 0,
                        disableOnInteraction:false,
                      }}
                      loop={true}
                      speed={3000}
                      grabCursor={true}
                      pagination={{
                       clickable: false,
                      }}
                      navigation={false}
                      modules={[Autoplay]}
                      breakpoints={{
                        // For small devices like phones
                        320: {
                          slidesPerView: 1, // Show 1 slide per view on small screens
                          spaceBetween: 20,
                        },
                        450: {
                          slidesPerView: 1, // Show 1 slide per view on small screens
                          spaceBetween: 20,
                        },
                        // For tablets
                        768: {
                          slidesPerView: 2, // Show 2 slides per view on tablet screens
                          spaceBetween: 20,
                        },
                        // For desktops or larger screens
                        1024: {
                          slidesPerView: 3, // Default behavior for larger screens
                          spaceBetween: 30,
                        },
                      }}
                      className="mySwiper linear-slide"
                  >

          <SwiperSlide>
          
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                    <img src="static/images/test1.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>  
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Rob Minilaloff</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                    I have had nothing but an excellent experience every time i have used these guys. Prompt service and extremely creative!!</p>
                  </div>
              
          </SwiperSlide>
          <SwiperSlide>
          
    
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                 <img src="static/images/about/avatar2.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>   
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Klmberly</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  Prompt and extremely professional! Absolutely loved the design work!</p>
            </div>
       
          </SwiperSlide>

          <SwiperSlide>
          
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                    <img src="static/images/test2.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>  
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Carly Cline</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  Fast and friendly service, easy to communicate with and delivered an amazing logo and website for our new company that exceeded our expectations!</p>
            </div>
    
          </SwiperSlide>

          <SwiperSlide>
          
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                    <img src="static/images/test1.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>  
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Rob Minilaloff</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                    I have had nothing but an excellent experience every time i have used these guys. Prompt service and extremely creative!!</p>
                  </div>
              
          </SwiperSlide>
          <SwiperSlide>
          
    
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                 <img src="static/images/about/avatar2.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>   
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Klmberly</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  Prompt and extremely professional! Absolutely loved the design work!</p>
            </div>
       
          </SwiperSlide>

          <SwiperSlide>
          
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                    <img src="static/images/test2.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>  
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Carly Cline</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  Fast and friendly service, easy to communicate with and delivered an amazing logo and website for our new company that exceeded our expectations!</p>
            </div>
    
          </SwiperSlide>
        </Swiper>


        

          

      
        <Swiper
                      slidesPerView={3}
                      autoplay={{
                        delay: 0.00,
                        disableOnInteraction: false,
                        reverseDirection: true, 
                      }}
                      loop={true}
                      grabCursor={true}
                      speed={3000}
                      
                      pagination={{
                      clickable: false,
                      }}
                      navigation={false}
                      modules={[Autoplay]}
                      breakpoints={{
                        // For small devices like phones
                        320: {
                          slidesPerView: 1, // Show 1 slide per view on small screens
                          spaceBetween: 10,
                        },
                        // For tablets
                        768: {
                          slidesPerView: 2, // Show 2 slides per view on tablet screens
                          spaceBetween: 20,
                        },
                        // For desktops or larger screens
                        1024: {
                          slidesPerView: 3, // Default behavior for larger screens
                          spaceBetween: 30,
                        },
                      }}
                      className="mySwiper2 mt-4 reverse-swiper linear-slide"
                  >
         
        <SwiperSlide className="reverse-slide">
          
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                 <img src="static/images/about/avatar1.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img> 
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Jason Brace</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  They came up with a great design right away and gave me a few different opitions through out the process. I will use them again</p>
            </div>
          

        </SwiperSlide>
        <SwiperSlide className="reverse-slide">
                      
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                 <img src="static/images/about/avatar1.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>   
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Nevzat</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  We thank you for the excellent work in the past. Creative, progressive, modern and does a great job! Again and again!</p>
            </div>
        
        </SwiperSlide>
                    
        <SwiperSlide className="reverse-slide">
          
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                    <img src="static/images/test2.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>  
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Carly Cline</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  Fast and friendly service, easy to communicate with and delivered an amazing logo and website for our new company that exceeded our expectations!</p>
            </div>
        </SwiperSlide>

        <SwiperSlide className="reverse-slide">
          
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                 <img src="static/images/about/avatar1.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img> 
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Jason Brace</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  They came up with a great design right away and gave me a few different opitions through out the process. I will use them again</p>
            </div>
          

        </SwiperSlide>
        <SwiperSlide className="reverse-slide">
                      
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                 <img src="static/images/about/avatar1.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>   
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Nevzat</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  We thank you for the excellent work in the past. Creative, progressive, modern and does a great job! Again and again!</p>
            </div>
        
        </SwiperSlide>
                    
        <SwiperSlide className="reverse-slide">
          
          <div className="testimonial mx-auto">
                 <div className="d-flex align-items-center">
                 <div className='my-2'>
                    <img src="static/images/test2.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='40px'></img>  
                  </div>
                  <div className='mx-2'>
                    <h6 className='mb-0'>Carly Cline</h6>

                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                          " className='mt-1'>
                            <div className=''>
                              <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid' width="80px"></img>
                            </div>
                    </a>
                  </div>
                 </div>
               
                  <p>
                  Fast and friendly service, easy to communicate with and delivered an amazing logo and website for our new company that exceeded our expectations!</p>
            </div>
        </SwiperSlide>
       

      </Swiper>

     






      
        
     
        
      </div>
      
      <div className="blur-effect2"></div>

    </section>

    </>
  )
}

import React from 'react'
// import $ from 'jquery'; 
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import '@fancyapps/fancybox';



export default function Website(props) {

    // useEffect(() => {
    //     try {
    //         $('[data-fancybox="gallery"]').fancybox();

    //     } catch (error) {
    //         console.error('Error initializing Fancybox:', error);
    //     }
    // }, []);


  return (
    <>
        

            <div className="row gx-4">
               

            <div className=" col-md-6 mt-4">
                    <div className="folio-card " style={{background:`url(${props.image1})`,backgroundSize:'cover',backgroundPosition:'center'}}>
                    <a href={props.image1} data-fancybox="gallery" className=''>
                        <div className="card-overlay ">
                            <div className="row">
                                <div className="col-10">
                                    <h3 className="fw-bold text-white mt-2">{props.name1}</h3>
                                    <p className="text-white fs-6 mt-3">Project was about precision and information...</p>
                                </div>
                                <div className="col-2 my-auto">
                                    <div className='text-end'>
                                        <i className="fa-solid fa-arrow-up-long text-white arrow-icon"></i>
                                    </div>
                                </div>    
                            </div>       
                        </div>
                        </a>
                    </div>
                    <div className='text-center'>
                    <a href={props.link1} target='_blank' rel='noreferrer' className='btn btn-outline-dark my-2 text-center px-3'>
                        <i className="fa-regular fa-eye fs-6"></i> Live Demo
                    </a>
                    </div>
                </div>
                
                <div className=" col-md-6 mt-4">
                    <div className="folio-card " style={{background:`url(${props.image2})`,backgroundSize:'cover',backgroundPosition:'center'}}>
                    <a href={props.image2} data-fancybox="gallery" className=''>
                        <div className="card-overlay ">
                            <div className="row">
                                <div className="col-10">
                                    <h3 className="fw-bold text-white mt-2">{props.name2}</h3>
                                    <p className="text-white fs-6 mt-3">Project was about precision and information...</p>
                                </div>
                                <div className="col-2 my-auto">
                                    <div className='text-end'>
                                        <i className="fa-solid fa-arrow-up-long text-white arrow-icon"></i>
                                    </div>
                                </div>    
                            </div>       
                        </div>
                        </a>
                    </div>
                    <div className='text-center'>
                    <a href={props.link2} target='_blank' rel='noreferrer' className='btn btn-outline-dark my-2 text-center px-3'>
                        <i className="fa-regular fa-eye fs-6"></i> Live Demo
                    </a>
                    </div>
                   
                </div>

           

            </div>
            

      

    
    </>
  )
}

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

// import 'swiper/swiper.min.css';
import 'swiper/css';
import 'swiper/css/pagination';


// import './styles.css';
// import required modules
import {Autoplay, EffectFade }from 'swiper/modules';

export default function App() {
  return (
    <>
        <section className="padding  renew-banner ">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6 my-auto">
                    <Swiper
                        effect="slide" 
                        autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                        }}
                        loop={true}
                        grabCursor={true}
                        
                        pagination={{
                        clickable: false,
                        }}
                        navigation={false}
                        modules={[Autoplay, EffectFade]}
                        className="mySwiper"
                    >
                         <SwiperSlide>
                            <div className="my-auto" >
                                <div className="banner-content text-md-start text-center">
                                    <h1 className="banner-heading">Seeking a Complete Digital Solution? </h1>
                                    <h2 className="banner-heading">Your Search Ends Here..! </h2>
                                    <p>
                                        Partner with us to achieved remarkable results with our proven expertise
                                    </p>
                                   
                                </div>
                            </div> 
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="my-auto" style={{}}>
                                    <div className="banner-content text-md-start text-center">
                                        <h1 className="banner-heading">Elevate your brand with innovative strategies and creative digital solutions.</h1>
                                        <p className=''>
                                            Our Stunning Graphic Design Solutions   
                                        </p>
                                      
                                    </div>
                            </div> 
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="my-auto" style={{}}>
                            <div className="banner-content text-md-start text-center">
                                <h1 className="banner-heading">Build your digital presence with impactful strategies and creative solutions.</h1>
                                <p className=''>
                                    Expert Website Development
                                </p>
                               
                            </div>
                        </div> 
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="my-auto" style={{}}>
                            <div className="banner-content text-md-start text-center">
                                <h1 className="banner-heading">Optimize your online presence with tailored strategies and innovative solutions.</h1>
                                <p className=''>
                                    Our Proven SEO Strategies
                                </p>
                               
                            </div>
                        </div> 
                    </SwiperSlide>


                    </Swiper>
                        
                    <div className='text-md-start text-center'>
                    <Link to="/contact-us" className="btn btn-1 mt-3">Get Started</Link>
                    <Link to="/services" className="btn btn-5 mx-3  mt-3">More</Link>
                    </div>

                    </div>
                    <div className="col-lg-6 mt-md-0 mt-4">
                        <img src="static/images/sliders/slider_side.png" alt="" loading="lazy" className="img-fluid"  ></img>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}


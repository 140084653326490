import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination }from 'swiper/modules';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import Testimonials from './Testimonials';



export default function About(props) {
  const [showVideoOverlay, setShowVideoOverlay] = useState(false);
  const [Counter,setCounter] = useState(false)


  const openVideoOverlay = () => {
    setShowVideoOverlay(true);
    document.body.style.overflowY = 'hidden'; // Prevent scrolling when overlay is open
  };

  const closeVideoOverlay = () => {
    setShowVideoOverlay(false);
    document.body.style.overflowY = 'auto'; // Allow scrolling when overlay is closed
  };
  return (
    <>
      <div className="about-banner">
          <div className="container">
            <h1 className='text-center'>
              We are ”Media Production Art” - product and branding and creative agency based in Florida
            </h1>
            <div className="row mt-5 col-lg-8 mx-auto justify-content-between align-items-star text-lg-start text-center">
              <h5 className="fw-bold col-lg-3 ">
                01. ABOUT US 
              </h5>
              <p className="border-custom mt-3 col-lg-2 mx-lg-0 mx-auto"></p>
              <p className='fw-bold text-secondary col-lg-7 '>
                  Unlocking creative horizons: the story behind “Media Production Art” a global digital agency crafting experiences, building dreams, and shaping success.
              </p>
            </div>
          </div>
      </div>

      <section className="padding">
        <div className="container-fluid">
          
          <div className="row gx-2">
            <div className="col-lg-6">
              <div className='big-img'>
                  <img src="static/images/about/1.jpg" alt="" className='img-fluid'></img>
              </div>
            </div>

            <div className="col-lg-6">
              <ScrollTrigger onEnter={()=> setCounter(true)} onExit={()=> setCounter(false)}>
              <div className='row gx-2 mt-lg-0 mt-3'>
                  <div className="col-md-6">
                  <div className='img-sm'>
                    <img src="static/images/about/3.jpg" alt="" className='img-fluid'></img>
                  </div>
                  <div className='mt-2 bg-dark-gradient p-4'>
                        <h4 className="text-white">
                          We helped to get companies with $25M+ funding
                        </h4>
                        {Counter && <h1 className='text-white mt-5' style={{'fontSize':'5rem'}}><CountUp start={0} end={25} duration={5} />M<sup>+</sup></h1>}
                  </div>
                  </div>
                  <div className="col-md-6 mt-md-0 mt-2">
                  <div className='bg-light-gradient p-4'>
                  <h4 className="">
                  We have 100+ satisfied clients
                        </h4>
                        {Counter && <h1 className=' mt-5' style={{'fontSize':'5rem'}}><CountUp start={0} end={100} duration={5} />K</h1>}

                  </div>
                  <div className='mt-2 img-sm'>
                    <img src="static/images/about/2.jpg" alt="" className='img-fluid'></img>
                  </div>
                  </div>

              </div>
            </ScrollTrigger>
            </div>

          </div>
          
         

            </div>
        </section>


      <section className="padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 my-auto">
              <h4>WHO WE ARE</h4>
            </div>
            <div className="col-lg-8">
              <div className="col-md-10">
              <h1>
              We make storable strategy growth your company with Media Production Art!
              </h1>
              <p className="text-secondary mt-4">
              We deploy world-class creative design, team on demand. that can design
              surest measure’s of success is when a client partner with more than once
              build, ship scale your vision most efficient.
              </p>

              </div>
              <div className="row mt-5">
                <div className="col-md-4 col-6 " style={{'borderRight':'1px solid rgba(0,0,0,0.2)'}}>
                    <div className="d-flex">
                      <div className='' style={{'marginTop':'14px'}}>
                          <img src="static/images/about/layer.svg" alt="" className='img-fluid' width={'30px'}></img>
                      </div>
                      <div className='mx-2'>
                        <h1 className='' style={{'fontWeight':'500'}}>50<sup>+</sup></h1>
                        <p className="text-secondary mb-0" style={{'fontWeight':'500'}}>
                        projects success
                        </p>
                        <p className='text-secondary' style={{'fontWeight':'500'}}>
                        rate 99%
                        </p>
                      </div>
                  </div>
                </div>
                <div className="col-md-4 col-6 mx-md-5 mx-0">
                <div className="d-flex">
                <div className='' style={{'marginTop':'14px'}}>
                    <img src="static/images/about/award.svg" alt="" className='img-fluid' width={'30px'}></img>
                </div>
                <div className='mx-2'>
                  <h1 className='' style={{'fontWeight':'500'}}>12<sup>+</sup></h1>
                  <p className="text-secondary mb-0" style={{'fontWeight':'500'}}>
                    award for digital 
                  </p>
                  <p className='text-secondary' style={{'fontWeight':'500'}}>
                  innovation
                  </p>
                </div>
              </div>
                </div>
            
             

              </div>
            </div>
          </div>
        </div>
      </section>

     
      <section className="padding">
        <div className="container">
          <h1 className="fw-bold lined2 text-center">Quality team</h1>
          <p className="mt-3 text-center col-md-6 mx-auto">
          We deploy world-class creative design, team on demand. that can design surest measure’s of success is when a client partner with more than once
          build, ship scale your vision most efficient.
          </p>
          <div className="row mt-5">
            <div className="col-lg-3 col-md-6 text-center mt-3">
                <img src="static/images/about/zubair.webp" alt=""  loading="lazy" className='img-fluid team mt-6'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Zubair Ahmed</h4>
                  <p className='text-blue fw-bold'>CEO / Founder</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center mt-3">
                <img src="static/images/about/fareed.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Fareed Soomro</h4>
                  <p className='text-blue fw-bold'>Director</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center mt-3">
                <img src="static/images/about/TaJanna.webp" alt=""  loading="lazy" className='img-fluid team mt-6'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>TaJanna Mallory</h4>
                  <p className='text-blue fw-bold'>Director USA Office</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center mt-3">
                <img src="static/images/about/afroz.jpg" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Afroz Ali Abbasi</h4>
                  <p className='text-blue fw-bold'>HR Manager | Team Lead</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center mt-3">
                <img src="static/images/about/dabeer.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Dabeer Siddiquie</h4>
                  <p className='text-blue fw-bold'>Graphic Designer</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 text-center mt-3">
                <img src="static/images/about/mubeen.webp" alt=""  loading="lazy" className='img-fluid team mt-6'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Mubeen Ali</h4>
                  <p className='text-blue fw-bold'>Graphic Designer</p>
                </div>
            </div>
            
            
            
            <div className="col-lg-3 col-md-6 text-center mt-3">
                <img src="static/images/about/ameeque.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Syed Ameeque</h4>
                  <p className='text-blue fw-bold'>Full Stack Developer</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center mt-3">
                <img src="static/images/about/saad.webp" alt=""  loading="lazy" className='img-fluid team mt-6'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Saad Khan</h4>
                  <p className='text-blue fw-bold'>Front End Developer</p>
                </div>
            </div>
           
            <div className="col-lg-3 col-md-6 text-center mt-3">
                <img src="static/images/about/narmeen.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Narmeen</h4>
                  <p className='text-blue fw-bold'>Front Desk Officer</p>
                </div>
            </div>
          </div>
        </div>
      </section>


      <section className="team-bg padding">
          
      </section>
    


      <section className="padding">
          <div className="container">
            <div className="row g-0">
                <div className="col-lg-6 mt-lg-0 mt-3" >
                        <img src="static/images/contact/7.jpg" alt="" className='img-fluid object-fit-cover ' style={{'height':'90vh', 'objectFit':'cover'}}></img>
                </div>
                <div className="col-lg-6 px-md-4 text-md-start text-center p-3 py-5 fs-14" style={{'min-height':'90vh', 'background':'rgb(250,250,250)'}}>
                      <h1 className='lined2 '>Why Choose Us</h1>
                      <p className="mt-3">
                      Choose MPA for unparalleled expertise and dedication to excellence. With a proven track record since 2012, we stand at the forefront of digital innovation, delivering tailored solutions that drive real results. 

                      </p>

                      <p>
                      Our team of seasoned professionals is committed to understanding your unique needs and surpassing your expectations, ensuring every project is executed with precision and professionalism.
                      </p>
                  

                    <div className="row mt-4 px-0">

                    
                    <div className="col-md-6 p-0 m-0">
                        <div className="about-card ">
                         <div>
                         <img src="static/images/about/icons-02.webp" alt=""  loading="lazy" className="img-fluid" width="40px"></img>
                         </div>
                            <p className='mx-2 fs-14  my-auto'>Proven Results</p>
                        </div>
                    </div>

                    <div className="col-md-6 p-0 m-0">
                        <div className="about-card ">
                           <div>
                           <img src="static/images/about/icons-03.webp" alt=""  loading="lazy" className="img-fluid" width="40px"></img>
                           </div>
                            <p className='mx-2 fs-14  my-auto'>100% Quality Work</p>
                        </div>
                    </div>

                    <div className="col-md-6 p-0 m-0 mt-3">
                        <div className="about-card text-center">
                           <div>
                           <img src="static/images/about/icons-04.webp" alt=""  loading="lazy" className="img-fluid" width="40px"></img>
                           </div>
                            <p className='mx-2 fs-14  my-auto'>Tailored Solutions</p>
                        </div>
                    </div>
                    <div className="col-md-6 p-0 m-0 mt-3">
                        <div className="about-card text-center">
                           <div>
                           <img src="static/images/about/icons-05.webp" alt=""  loading="lazy" className="img-fluid" width="40px"></img>
                           </div>
                            <p className='mx-2 fs-14  my-auto'>Client-Centric Approach</p>
                        </div>
                    </div>

                  </div>

                </div>
                

              

            </div>
          </div>
      </section>

      <section className="padding bg-light">
          <div className="container-fluid">
              
                <div className="row gx-0">
                  <div className="col-md-2 col-sm-6 offset-md-2 order-md-1 order-2">
                    <div className="logo-parent parent-logo-border1">
                      <img src="static/images/services/logo1.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    
                  <div className="offset-md-1 col-md-6 my-auto order-md-2 order-1 mb-md-auto mb-3 text-md-start text-center">
                      <h2 className='mb-0'>
                        We worked with largest 
                      </h2>
                      <h2>global brands</h2>
                  </div>    
                  <div className="col-md-2 col-sm-6 offset-md-2"></div>    
                  <div className="offset-md-1"></div>    

                </div>

                <div className="row gx-0">
                  <div className="col-md-2 col-sm-6">
                    <div className="logo-parent parent-logo-border1">
                      <img src="static/images/services/logo7.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    
                  <div className="col-md-2 col-sm-6">
                    <div className="logo-parent parent-logo-border2-3">
                      <img src="static/images/services/logo2.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    
                  <div className="col-md-2 col-sm-6">
                  <div className="logo-parent parent-logo-border2-2">
                      <img src="static/images/services/logo3.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>    
                  </div>    
                  <div className="col-md-2 col-sm-6">
                  <div className="logo-parent parent-logo-border2-2">
                      <img src="static/images/services/logo4.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    
                  <div className="col-md-2 col-sm-6">
                  <div className="logo-parent parent-logo-border2-2">
                      <img src="static/images/services/logo5.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    
                  <div className="offset-md-2"></div>    

                </div>

                <div className="row gx-0">

                  <div className="col-md-2 col-sm-6 offset-md-2">
                    <div className="logo-parent parent-logo-border3">
                      <img src="static/images/services/logo12.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    
                  <div className="col-md-2 col-sm-6">
                    <div className="logo-parent parent-logo-border3">
                      <img src="static/images/services/logo8.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    
                  <div className="col-md-2 col-sm-6">
                  <div className="logo-parent parent-logo-border3">
                      <img src="static/images/services/logo9.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>    
                  </div>    
                  <div className="col-md-2 col-sm-6">
                  <div className="logo-parent parent-logo-border3">
                      <img src="static/images/services/logo10.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    
                  <div className="col-md-2 col-sm-6">
                  <div className="logo-parent parent-logo-border1">
                      <img src="static/images/services/logo11.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    

                </div>


                <div className="row gx-0">
                  
                  <div className="col-md-2 col-sm-6 offset-md-8">
                    <div className="logo-parent parent-logo-border4">
                      <img src="static/images/services/logo13.webp" alt="" className='img-fluid' width={'100px'} ></img>  
                    </div>  
                  </div>    
                  <div className=" offset-md-2">
                  
                  </div>    

                </div>
                 
                
              
          </div>
        </section>

        <Testimonials/>


    </>
  )
}
